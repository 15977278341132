import React, {useEffect} from 'react';
import {  LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledCom = styled.div`
  .banner-holder{
		height: 400px; display: flex; align-items: flex-end; justify-content: center; padding-bottom: 70px; 
		
		.image-holder{height: 400px; position: absolute; top: 0; left: 0}
		.content-data{position: relative; z-index: 1; text-align: center}
		.content-data h1{font-size: 48px; margin-top: 20px; position: relative; font-weight: 800; text-align: center; text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.70); color: var(--text-light); }
		.content-data h1::after{content: ""; position: absolute;  height: 40%; width: 100%; background: var(--primary-color); bottom: 0; left: 50%; transform: translateX(-50%); z-index: -1}
		.sub-title{background: var(--primary-color); color: #ffffff; font-size: 18px; letter-spacing: 2px; padding: 2px 10px; font-weight: 700; margin-bottom: 20px}
		@media(max-width: 1024px){
			.content-data h1{font-size: 32px; }
		}
	}


`;
const PrivacyPolicy = () => {
  useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <StyledCom className=''>
        <div className='banner-holder'>
            <div className="image-holder">
                <LazyLoadImage src={'./images/privacy-policy.jpg'} alt={'banner'} />
            </div>
            <div className='content-data'>
                <h1>Privacy Policy for DMV-gc</h1>
            </div>
        </div>
        <div className='container'>
            <div className='my-5'>
                <h3 className='mb-2'>Privacy Policy:</h3>
                <div className='description-holder'>
                    <p>At DMV-GC, accessible from <Link className='text-secondary font-weight-700' to="https://www.dmv-gc.com">www.dmv-gc.com</Link> , your privacy is one of our top priorities. This Privacy Policy explains what information we collect, how we use it, and how we protect it.</p>
                    <p>If you have any questions or need more information about our Privacy Policy, feel free to get in touch with us anytime.</p>
                    <p>This Privacy Policy only applies to our online activities and covers the information you share with us or that we collect from our website. It does not apply to any information collected offline or through channels other than this website.</p>
                </div>
                <h3 className='mb-2 mt-3'>Consent:</h3>
                <div className='description-holder'>
                    <p>By using our website, you agree to our Privacy Policy and consent to the collection and use of information as outlined here.</p>
                </div>
                <h3 className='mb-2 mt-3'>Information We Collect:</h3>
                <div className='description-holder'>
                    <p>We may ask you to provide personal information, such as your name, email address, phone number, and other details, when you contact us or interact with our website. We collect this information to help us respond to your queries or provide the services you need.</p>
                    <p>When you register for an account, we may request additional contact information such as your company name, address, and other details.</p>
                </div>
                <h3 className='mb-2 mt-3'>How We Use Your Information:</h3>
                <div className='description-holder'>
                    <p>We use the information we collect for several purposes, including to:</p>
                    <ul>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve and personalize your experience on our site</li>
                        <li>Understand how you use our website and improve our services</li>
                        <li>Communicate with you for customer service, updates, and promotional purposes</li>
                        <li>Prevent fraud and keep our site secure</li>
                        <li>Send you emails and respond to your inquiries</li>
                    </ul>
                </div>
                <h3 className='mb-2 mt-3'>Log Files:</h3>
                <div className='description-holder'>
                    <p>Like most websites, DMV-gc uses log files. These files log visitors' visits to our site, and the information collected includes your IP address, browser type, Internet Service Provider (ISP), date and time of visit, referring/exit pages, and the number of clicks. This data is used to analyze trends, administer the site, and track user behavior, but it does not contain any personally identifiable information.</p>
                </div>
                <h3 className='mb-2 mt-3'>Cookies and Web Beacons:</h3>
                <div className='description-holder'>
                    <p>DMV-GC uses cookies to enhance your browsing experience. Cookies store information about visitors' preferences and the pages they visit, helping us customize our website content for a better user experience. You can control how cookies are used by adjusting your browser settings.</p>
                </div>
                <h3 className='mb-2 mt-3'>Advertising Partners Privacy Policies:</h3>
                <div className='description-holder'>
                    <p>We work with third-party advertisers who may use cookies, JavaScript, or Web Beacons to personalize the ads you see. These technologies are used to measure the effectiveness of their ad campaigns or to serve you ads based on your activity across different websites.</p>
                    <p>Please note that DMV-GC does not have access to or control over these third-party cookies.</p>
                </div>
                <h3 className='mb-2 mt-3'>Third-Party Privacy Policies:</h3>
                <div className='description-holder'>
                    <p>Our Privacy Policy does not apply to third-party advertisers or websites. We encourage you to review the privacy policies of any external services or advertisers to learn more about how they handle your information.</p>
                    <p>You can manage cookie settings through your browser's options. For more detailed information, please refer to your browser’s help section.</p>
                </div>
                <h3 className='mb-2 mt-3'>Privacy Rights Under Maryland Law:</h3>
                <div className='description-holder'>
                    <p>Under Maryland law, residents have specific privacy rights regarding their personal information. These rights include:</p>
                    <p>
                        <ul>
                            <li>You can request details about the personal data that a business collects and holds about you. </li>
                            <li>You can request copies of your personal data that a business holds. </li>
                            <li>You can request that we correct any inaccurate or incomplete personal information. </li>
                            <li>You have the right to request the deletion of your personal data under certain conditions.</li>
                        </ul>
                    </p>
                    <p>If you wish to exercise any of these rights, please contact us. We have one month to respond to your request.</p>
                </div>
                <h3 className='mb-2 mt-3'>GDPR Data Protection Rights:</h3>
                <div className='description-holder'>
                    <p>Under the General Data Protection Regulation (GDPR), all users have the following rights: </p>
                    <ul>
                        <li><strong>The right to access:</strong> You can request copies of your personal data.</li>
                        <li><strong>The right to rectification: </strong> You can request that we correct or complete inaccurate information.</li>
                        <li><strong>The right to erasure: </strong> You can request that we delete your personal data under certain conditions.</li>
                        <li><strong>The right to restrict processing: </strong> You can request that we limit the use of your personal data under certain conditions.</li>
                        <li><strong>The right to object to processing: </strong> You can object to our processing of your data under certain conditions.</li>
                        {/* <li><strong>The right to data portability: </strong> You can request that we transfer your data to another organization or directly to you.</li> */}
                    </ul>
                    <p>If you’d like to exercise any of these rights, contact us. We’ll respond to your request within one month.</p>
                </div>
                <h3 className='mb-2 mt-3'>Children's Information:</h3>
                <div className='description-holder'>
                    <p>Protecting children’s privacy is important to us. We encourage parents and guardians to monitor their children's online activities.</p>
                    <p>DMV-GC does not knowingly collect personal information from children under 13. If you believe that your child has shared personal information with us, please contact us immediately, and we will take steps to remove that information from our records.</p>
                </div>
            </div>
        </div>
    </StyledCom>
  );
};

export default PrivacyPolicy;
